<template>
  <div class="blockly-wrapper">
    <div class="blockly" :class="{'fullscreen-video': fullscreenVideo}">
      <div class="video-bg" v-if="fullscreenVideo">
        <a href="#" @click.prevent.stop="fullscreenVideo = false" class="close-video">
          <img src="@/assets/images/close.png" alt="Close">
        </a>
      </div>

      <div class="logo-wrapper">
        <img src="@/assets/images/white-logo.png" alt="">
      </div>

      <!-- form -->
      <form class="blockly-form">
        <button @click.prevent="load">Load</button>
        <div class="row no-gutters">
          <div class="col-6">
            <select v-model="gameOption">
              <option v-for="game in games" :value='game.name'>{{ game.name }}</option>
            </select>
          </div>
          <div class="col-6">
            <input type="text" class="form-control" id="name" name="name">
          </div>
        </div>
        <button @click.prevent="save">Save</button>
      </form>

      <div class="row mt-4">
        <div :class="{'col-xl-6': !current, 'col-xl-9': current == 'game', 'col-xl-3': current == 'video'}">
          <div class="bg_footer"></div>
          <div data-v-4759b31d="" class="coding-text"><img data-v-4759b31d="" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAAWCAYAAAAvikJUAAAABHNCSVQICAgIfAhkiAAAAapJREFUeF7t289Kw0AQx/FJ0jaIin8qiqIQEJRCvRSP9jH6Pp48KFIUPPgKvo/00BY8e1NPhdjYRBBKa5vsBpzFb+4zmXyW/kh3W09Mr8fhhryNdyTwA6kk66ZtqEMAgT8SiL0Pr/CtH17XJH6PJnVh4VoKEEBAj0DhAOi+7IsfH+h5AiZBAAFjgUIBwIff2JlCBFQK5A6A79f+U5UPwVAIIGAmkCsAOk+BXLQafOc3M6YKAbUCuQKAV3+168dgCFgJLA2Ay+eabK80JBlXrG5EMQII6BNYGgDdXiR+ta5vciZCAAFrgYUBwMaftS8NEFAtsDAAbocn/MJP9fIxHAJ2Ar8GwFWvLqvVyK471QggoFpgbgCkx37t8yYbf6qXjuEQsBeYGwAc+9nD0gEBFwRmAiA99tsKz1yYnRkRQMBSYCYArvvHEnqblm0pRwABFwSmAoBjPxeWjBkRKE9gKgDuBs1JZ/7jXx4vnRDQLfATADeDXanJke5pmQ4BBEoVyAKAY79STWmGgDMCWQDc9w8l8facGZpBEUCgHIEsANLNPy4EEPh/AqPR5xfy2mZhO8nqSwAAAABJRU5ErkJggg=="><p data-v-4759b31d="">Coding</p></div>
          <div id="blocklyDiv" class="m-0">

            <button @click.prevent="runCode" class="start-now">
              <span>Start Now</span>
            </button>
          </div>

          <textarea
            class="show-code-area d-none"
            id="xml"
            name="xml"
            rows="8"
          ></textarea>

          <xml id="workspaceBlocks" style="display:none">
            <block type="onstart" x="13" y="13"></block>
            <!--<block type="forever" x="188" y="13"> </block>-->
            <!--<block type="if" x="188" y="100"> </block> -->
          </xml>
          <xml id="toolbox" style="display:none">
            <category name="Motor">
              <block type="run"></block>
              <block type="ramp"></block>
              <block type="tankmotors"></block>
              <block type="steermotors"></block>
              <!--<block type="pauseuntil_ready"> </block>
              <block type="pauseuntil_stalled"> </block>-->
              <block type="stop"></block>
              <block type="reset"></block>
              <block type="stopallmotors"></block>
              <block type="resetallmotors"></block>
            </category>
            <category name="Sensor">
              <label text="Touch Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_touch"> </block>-->
              <block type="pause_until_touch"></block>
              <block type="touch_n_is_pressed"></block>
              <label text="Color Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_color_sensor_detected"> </block>-->
              <block type="pause_until_color"></block>
              <block type="is_color_sensor_n_detected"></block>
              <block type="color_sensor_n_color"></block>

              <block type="on_color_sensor_light"></block>
              <block type="pause_until_color_light"></block>
              <block type="color_sensor_light"></block>

              <!--<block type="color"> </block>
             <block type="on_color_sensor_light"> </block>
             <block type="pause_until_color_light"> </block>
             <block type="color_sensor_light"> </block>
             <block type="color_sensor_rgb_raw"> </block>-->
              <label text="Ultrasonic Sensor" web-class="myLabelStyle"></label>
              <!--<block type="on_ultrasonic_detected"> </block>-->
              <block type="pause_until_ultrasonic"></block>
              <block type="ultrasonic_distance"></block>
              <label text="Gyro Sensor" web-class="myLabelStyle"></label>
              <block type="gyro_rate"></block>
              <block type="gyro_angle"></block>
              <block type="pause_until_gyro"></block>
              <block type="reset_gyro"></block>
            </category>
            <category name="Loops">
              <block type="onstart"></block>
              <block type="forever"></block>
              <block type="repeat"></block>
              <block type="pause"></block>
              <block type="while_loop"></block>
            </category>
            <category name="Logic">
              <block type="controls_if"></block>
              <block type="logic_compare">
                <field name="OP">EQ</field>
              </block>
              <block type="logic_operation">
                <field name="OP">AND</field>
              </block>
              <block type="logic_negate"></block>
              <block type="logic_boolean">
                <field name="BOOL">TRUE</field>
              </block>
              <block type="math_number"></block>
            </category>
            <category name="Functions">
              <block type="function_container"></block>
              <block type="function_caller"></block>
            </category>
          </xml>
        </div>

        <div :class="{'col-xl-6': !current, 'col-xl-9': current == 'video', 'col-xl-3': current == 'game'}">
          <div class="control-screen">
            <a href="javascript:void(0)" @click.prevent="current == 'video'? current = null: current = 'video'">
              <svg viewBox="0 0 9.642 19.312">
                <path id="Path_27138" data-name="Path 27138"
                      d="M9.67,9.642,12.615,6.7l6.7-6.7H13.394L9.67,3.724,5.918,0H0L6.7,6.7Z"
                      transform="translate(9.642) rotate(90)" fill="#fff"/>
              </svg>
            </a>
            <a href="javascript:void(0)" @click.prevent="current == 'game'? current = null: current = 'game'">
              <svg viewBox="0 0 9.643 19.312">
                <path id="Path_27140" data-name="Path 27140"
                      d="M9.67,0l2.944,2.945,6.7,6.7H13.394L9.67,5.918,5.918,9.643H0l6.7-6.7Z"
                      transform="translate(9.643) rotate(90)" fill="#fff"/>
              </svg>
            </a>
          </div>
          <div class="identification">
            <img src="@/assets/images/simulation-image.png" alt="">
            <p>Simulation</p>
          </div>
          <div class="d-flex">
            <div class="video-container">
              <div class="top-left abs-div"></div>
              <div class="left-top abs-div"></div>
              <div class="bottom abs-div"></div>
              <div class="right-bottom abs-div"></div>
              <div class="right-corner abs-div"></div>
              <div class="top-corner abs-div"></div>

              <iframe
                frameborder="0"
                id="circle"
                src="https://wro-v.com/robot2/index.html"
                scrolling="no"
              ></iframe>
              <a href="#" class="video-fullscreen-icon" @click.prevent.stop="fullscreenVideo = true">
                <img src="@/assets/images/full-screen.png" alt="">
              </a>
            </div>

            <div class="video-right">
              <img src="@/assets/images/vide-right.png" alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

    <footer>
      <div class="rt-section">
        <div class="row">
          <div class="col-md-3 ads">
            <img  src="@/assets/images/ads/1.jpeg">
            <img  src="@/assets/images/ads/1.jpeg">
          </div>
          <div class="col-md-6">
            <p class="font-weight-bold">
              <span class="ml-2">Note: </span>
              <span>For a better user experience, use your PC</span>
            </p>
            <p>
              “LEGO®, LEGO® MINDSTORMS® Education EV3 & LEGO® Education are
              trademarks/copyrights owned by the LEGO® Group who does not sponsor,
              authorize or endorse this content and experience.
            </p>
          </div>
          <div class="col-md-3 ads">
            <img src="@/assets/images/ads/2.jpeg">
            <img src="@/assets/images/ads/2.jpeg">
          </div>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import '../../blocks/stocks'
import Blockly from 'blockly';
import $ from 'jquery'
import Swal from 'sweetalert';


export default {
  name: "Blockly",
  data() {
    return {
      gameOption: null,
      options: {
        media: 'media/',
        toolbox:
          `<xml>
              <category name="Motor">
                  <block type="run"></block>
                  <block type="ramp"></block>
                  <block type="tankmotors"></block>
                  <block type="steermotors"></block>
                  <block type="stop"></block>
                  <block type="reset"></block>
                  <block type="stopallmotors"></block>
                  <block type="resetallmotors"></block>
              </category>
              <category name="Sensor">
                <label text="Touch Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_touch"></block>
                <block type="touch_n_is_pressed"></block>
                <label text="Color Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_color"></block>
                <block type="is_color_sensor_n_detected"></block>
                <block type="color_sensor_n_color"></block>
                <label text="Ultrasonic Sensor" web-class="myLabelStyle"></label>
                <block type="pause_until_ultrasonic"></block>
                <block type="ultrasonic_distance"></block>
                <label text="Gyro Sensor" web-class="myLabelStyle"></label>
                <block type="gyro_rate"></block>
                <block type="gyro_angle"></block>
                <block type="pause_until_gyro"></block>
                <block type="reset_gyro"></block>
              </category>
              <category name="Loops" colour="%{BKY_LOOPS_HUE}">
                <block type="onstart"></block>
                <block type="forever"></block>
                <block type="controls_repeat_ext">
                  <value name="TIMES">
                    <block type="math_number">
                      <field name="NUM">5</field>
                    </block>
                  </value>
                </block>
                <block type="pause"></block>
                <block type="while_loop" > </block>
              </category>
              <category name="Logic" colour="%{BKY_LOGIC_HUE}">
                <block type="controls_if"></block>
                <block type="logic_compare"></block>
                <block type="logic_operation"></block>
                <block type="logic_negate"></block>
                <block type="logic_boolean"></block>
                <block type="math_number"></block>
              </category>
              <category name="Functions" >
                <block type="function_container"> </block>
                <block type="function_caller"> </block>
              </category>
            </xml>`
      },
      workspace: null,
      games: [],
      current: null,
      fullscreenVideo: false
    }
  },
  mounted() {
    var screenw = $(window).width();
    if (screenw <= 766) {
      var redirectTimer = 4000;
      Swal({
        title: " ملاحظة : ",
        text: " للحصول على تجربة استخدام أفضل استخدام جهاز الحاسب الشخصي ",
        icon: "warning",
        confirmButtonText: "Cool",
        showConfirmButton: false,
        timer: redirectTimer,
      });
    }


    this.initBlockly()

    Blockly.Xml.domToWorkspace(
      document.getElementById("workspaceBlocks"),
      this.workspace
    );

    this.initJavascript(this.workspace);

    this.loadSavedGamed();

  },
  watch: {
    'current'() {
      setTimeout(() => {
        Blockly.svgResize(this.workspace);
        $('#blocklyTrash image').setAttribute('xlink:href', '../../media/sprite.png')
      }, 100)
    }
  },
  methods: {
    initBlockly() {
      var workspace = Blockly.inject("blocklyDiv", {
        media: "../../media/",
        toolbox: document.getElementById("toolbox"),
        zoom:
          {
            controls: true,
            wheel: true,
            startScale: 1.0,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
            pinch: true
          },
        trashcan: true
      });
      this.workspace = workspace
    },

    loadSavedGamed() {
      this.$API.blockly.loadSavedGames({organizer_id: this.$cookies.get('organizer_id'), id: this.$cookies.get('id')})
        .then(response => {
          this.games = response
        })
    },

    runCode() {
      window.LoopTrap = 1000;
      Blockly.JavaScript.INFINITE_LOOP_TRAP =
        'if (--window.LoopTrap == 0) throw "Infinite loop.";\n';
      var code = Blockly.JavaScript.workspaceToCode(this.workspace);
      Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
      try {
        console.log('success')
        // if (Blockly.JavaScript.workspaceToCode(this.workspace) == "") {
        //   var codeXml = (document.getElementById("xml").innerText = "");
        // }
        eval(code);
      } catch (e) {
        console.log('error')
      }
      var xml = $("#xml").val();
      var foramtedxml = this.formatXml(xml);
      document.getElementById("circle").contentWindow.RunCode(foramtedxml);
    },

    formatXml(xml) {
      var formatted = "<xml>";

      // formatted = s.substring(s.indexOf('\n')+1);

      formatted = formatted.replace(/^>.*/, "");

      var reg = /(>)(<)(\/*)/g;

      formatted = formatted.replace(/(<\s*)(.+:)/, "$1"); // str is now <ActionStatus/>

      xml = xml.replace(reg, "$1\r\n$2$3");

      xml = xml.slice(55);

      var pad = 0;

      $.each(xml.split("\r\n"), function (index, node) {
        var indent = 0;
        if (node.match(/.+<\/\w[^>]*>$/)) {
          indent = 0;
        } else if (node.match(/^<\/\w/)) {
          if (pad != 0) {
            pad -= 1;
          }
        } else {
          indent = 0;
        }
        // else if (node.match(/^<\w[^>]*[^\/]>.*$/)) {
        //     indent = 1;
        //   }
        var padding = "";

        for (var i = 0; i < pad; i++) {
          padding += "  ";
        }
        formatted += padding + node + "\r\n";
        pad += indent;
      });

      return formatted;
    },

    initJavascript(workspace) {
      Blockly.JavaScript['run'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['ramp'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['tankmotors'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['steermotors'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pauseuntil_ready'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pauseuntil_stalled'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['stop'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['reset'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['stopallmotors'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['resetallmotors'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['on_touch'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pause_until_touch'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['touch_n_is_pressed'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['on_color_sensor_detected'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pause_until_color'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['is_color_sensor_n_detected'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['color_sensor_n_color'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['color'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['on_color_sensor_light'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pause_until_color_light'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['color_sensor_light'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['color_sensor_rgb_raw'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['on_ultrasonic_detected'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pause_until_ultrasonic'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['ultrasonic_distance'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['gyro_rate'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['gyro_angle'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return [code, Blockly.JavaScript.ORDER_NONE];
      };

      Blockly.JavaScript['pause_until_gyro'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['reset_gyro'] = function () {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['onstart'] = function (block) {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['forever'] = function (block) {
        // TODO: Assemble JavaScript into code variable.
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['repeat'] = function (block) {
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['pause'] = function (block) {
        var xml = Blockly.Xml.workspaceToDom(workspace);
        var xml_text = Blockly.Xml.domToText(xml);
        var code = document.getElementById('xml').innerText = xml_text;
        return code;
      };

      Blockly.JavaScript['while_loop'] = function (block) {
        var code = '...;\n';
        return code;
      };

      Blockly.JavaScript['continue_block'] = function (block) {
        // TODO: Assemble JavaScript into code variable.
        var code = '...;\n';
        return code;
      };

      Blockly.JavaScript['break_block'] = function (block) {
        // TODO: Assemble JavaScript into code variable.
        var code = '...;\n';
        return code;
      };

      Blockly.JavaScript['function_container'] = function (block) {
        var code = '...;\n';
        return code;
      };

      Blockly.JavaScript['function_caller'] = function (block) {
        var code = '...;\n';
        return code;
      };
    },

    save() {
      window.LoopTrap = 1000;
      Blockly.JavaScript.INFINITE_LOOP_TRAP =
        'if (--window.LoopTrap == 0) throw "Infinite loop.";\n';
      var code = Blockly.JavaScript.workspaceToCode(this.workspace);
      Blockly.JavaScript.INFINITE_LOOP_TRAP = null;
      try {
        if (Blockly.JavaScript.workspaceToCode(this.workspace) == "") {
          var codeXml = (document.getElementById("xml").innerText = "");
        }
        eval(code);
      } catch (e) {
        console.log(e)
      }
      var xml = $("#xml").val();
      var name = $("#name").val();

      this.$API.blockly.saveGame({
        xml: xml,
        name: name,
        id: this.$cookies.get('id'),
        organizer_id: this.$cookies.get('organizer_id')
      })
        .then(() => {
          this.loadSavedGamed();
          Swal({
            position: "center",
            icon: "success",
            title: "Your save has been successful",
            showConfirmButton: false,
            timer: 1500,
          });
        })
    },

    load() {
      let that = this;
      this.$API.blockly.showGame({
        id: this.$cookies.get('id'),
        organizer_id: this.$cookies.get('organizer_id'),
        file: this.gameOption
      })
        .then(response => {
          var customTextElement = document.getElementById('workspaceBlocks');
          customTextElement.innerHTML = "";
          Blockly.mainWorkspace.clear();
          customTextElement.innerHTML = response[0].details;
          Blockly.Xml.domToWorkspace(
            document.getElementById("workspaceBlocks"),
            that.workspace
          );
        })
    }
  }
}
</script>

<style lang="scss" scoped>


</style>
